import { useRef, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const LandingPage = (props) => {

  const { windowDimensions, siteText } = props;
  const modal = useRef();
  const { windowWidth, windowHeight } = windowDimensions;
  const { intro } = siteText;
  const [isPositioned, setIsPositioned] = useState(false);
  const [pos, setPos] = useState({
    left: 0,
    top: 0
  });

  useEffect(() => {
    if (windowWidth && windowHeight && modal.current && intro) {
      setPos({
        left: windowWidth / 2 - modal.current.offsetWidth / 2 + 'px',
        top: windowHeight / 2 - modal.current.offsetHeight / 2 + 'px'
      });
      setIsPositioned(true);
      setTimeout(() => {
      }, 200);

    }
  }, [windowWidth, windowHeight, intro]);

  return (
    <motion.div
      ref={modal}
      drag={windowWidth < 768 ? false : true}
      className={`view view--landing-page modal full ${isPositioned === true ? 'visible' : 'hidden'}`}
      style={{
        left: pos.left,
        top: pos.top,
        maxHeight: windowHeight - 24 + 'px'
      }}
    >
      <div className="modal__inner">
        <div className="modal__header">
          <div className="modal__header__inner">
            {
              windowWidth < 768 ?
                <h1 className="modal__header__title">Internet Walks</h1>
                :
                <h1 className="modal__header__title">How do you take a walk with someone on the internet?</h1>
            }
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content__inner">
            <div>
              {
                intro &&
                <ReactMarkdown>{intro.replace(/\n/gi, '\n&nbsp;  ')}</ReactMarkdown>
              }
            </div>
            <div>
              <Link className="modal__button modal__button--large modal__button--continue" to="/reflection">Continue</Link>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default LandingPage;