import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Reflection = (props) => {

  const { siteText } = props;
  const { reflection } = siteText;
  const [loadedLevel, setLoadedLevel] = useState(0);

  useEffect(() => {

    let level = 0;
    let raf;

    const increaseLevel = () => {
      level = level + 0.05;
      setLoadedLevel(level);

      if (level < 100) {
        raf = requestAnimationFrame(increaseLevel);
      }
    }

    raf = requestAnimationFrame(increaseLevel);

    return () => {
      cancelAnimationFrame(raf);
    }

  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: .2 }}
      className="view view--reflection modal modal--prompt visible"
    >
      <div className="modal__content">
        <div>
          {
            reflection &&
            <ReactMarkdown>{reflection.replace(/\n/gi, '\n&nbsp;  ')}</ReactMarkdown>
          }
        </div>
        <div>
          {
            loadedLevel < 100 ?
              <div className="loading-bar">
                <div className="loading-bar__inner">
                  <div
                    className="loading-bar__level"
                    style={{
                      width: loadedLevel + '%'
                    }}
                  />
                </div>
              </div>
              :
              <Link className="modal__button--large modal__button--continue" to="/menu">Continue</Link>
          }
        </div>
      </div>
    </motion.div>
  )
}

export default Reflection;