import { useState, useEffect, useCallback, useRef } from 'react';
import LandingPage from './views/LandingPage.js';
import Reflection from './views/Reflection.js';
import About from './views/About.js';
import Instructions from './views/Instructions.js';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Menu from './views/Menu.js';
import Poem from './views/Poem.js';
import { cockpitUrl } from './utils/cockpit.js';
import Editor from './views/Editor.js';
import Editors from './views/Editors.js';
import NewPoem from './views/NewPoem.js';
import ClickAudioPlayer from './components/ClickAudioPlayer.js';
import AudioPlayer from './components/AudioPlayer.js';

function App() {

  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  const app = useRef(null);

  const [isMuted, setIsMuted] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [poemsData, setPoemsData] = useState({});
  const [siteOptions, setSiteOptions] = useState({});
  const [siteText, setSiteText] = useState({});

  const handleResize = () => {
    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  }

  const handleMouseMove = (e) => {
    let x, y;
    if (e.clientX && e.clientY) {
      x = e.clientX;
      y = e.clientY;
    }
    if (e.touches) {
      if (e.touches[0]) {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      }
    }
    if (x && y) {
      setMousePosition({
        x: x,
        y: y
      });
    }
    if (mousePosition) {

    }
  }

  const fetchPoemsData = useCallback(() => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/collections/get/poems?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setPoemsData(response);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  const fetchSiteOptions = useCallback(() => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/singletons/get/site_options?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setSiteOptions(response);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  const fetchSiteText = useCallback(() => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/singletons/get/site_text?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setSiteText(response);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  useEffect(() => {
    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
    let resizeTimeout;
    const setWindowResizeTimeout = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(
        handleResize, 200
      )
    }

    fetchPoemsData();
    fetchSiteOptions();
    fetchSiteText();

    window.addEventListener('resize', setWindowResizeTimeout);

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', setWindowResizeTimeout);
    }
  }, [fetchPoemsData, fetchSiteOptions, fetchSiteText]);

  return (
    <BrowserRouter basename="/">
      <Route
        path="/"
        render={(props) => (
          <div
            className="App app"
            ref={app}
            style={{
              height: windowDimensions.windowHeight + 'px',
              width: windowDimensions.windowWidth + 'px'
            }}
            onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove}
          >
            <div id="content">
              {
                props.location.pathname !== '/editors' && props.location.pathname.indexOf('/editor') !== 0 &&
                <Menu windowDimensions={windowDimensions} poemsData={poemsData} pathname={props.location.pathname} siteOptions={siteOptions} app={app} {...props} />
              }
              <Switch>
                <AnimatePresence>
                  <Route
                    exact path="/"
                    key="Landing Page"
                    render={(props) => (
                      <LandingPage windowDimensions={windowDimensions} history={props.history} siteText={siteText} app={app} />
                    )}
                  />
                  <Route
                    exact path="/reflection"
                    key="Reflection"
                    render={(props) => (
                      <Reflection windowDimensions={windowDimensions} history={props.history} siteText={siteText} app={app} />
                    )}
                  />
                  <Route
                    exact path="/about"
                    key="About"
                    render={(props) => (
                      <About windowDimensions={windowDimensions} history={props.history} siteText={siteText} app={app} />
                    )}
                  />
                  <Route
                    exact path="/instructions"
                    key="Instructions"
                    render={(props) => (
                      <Instructions windowDimensions={windowDimensions} history={props.history} siteText={siteText} app={app} />
                    )}
                  />
                  <Route
                    path="/poem/:uid"
                    key="Poem"
                    render={(props) => (
                      <Poem windowDimensions={windowDimensions} history={props.history} poemsData={poemsData} app={app} pathname={props.location.pathname} />
                    )}
                  />
                  <Route
                    path="/editors"
                    key="Editors"
                    render={(props) => (
                      <Editors windowDimensions={windowDimensions} history={props.history} poemsData={poemsData} fetchPoemsData={fetchPoemsData} />
                    )}
                  />
                  <Route
                    path="/editor/:uid"
                    key="Editor"
                    render={(props) => (
                      <Editor windowDimensions={windowDimensions} history={props.history} poemsData={poemsData} pathname={props.location.pathname} fetchPoemsData={fetchPoemsData} />
                    )}
                  />
                  <Route
                    path="/new-poem"
                    key="New Poem"
                    render={(props) => (
                      <NewPoem windowDimensions={windowDimensions} history={props.history} poemsData={poemsData} />
                    )}
                  />
                </AnimatePresence>
              </Switch>
              <AnimatePresence>
                {
                  props.location.pathname !== '/' && props.location.pathname !== '/reflection' &&
                  <motion.nav
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="nav"
                  >
                    <Link to="/about" className="link--about">about</Link>
                    <Link to="/instructions" className="link--instructions">instructions</Link>
                  </motion.nav>
                }
              </AnimatePresence>
              <button onClick={() => { setIsMuted(!isMuted) }} className="button--mute">{isMuted === true ? 'unmute' : 'mute'}</button>
            </div>
            {
              isMuted === false &&
              <ClickAudioPlayer poemsData={poemsData} pathname={props.location.pathname} siteOptions={siteOptions} />
            }
            {
              isMuted === false &&
              props.location.pathname.indexOf('/poem') !== 0 &&
              <AudioPlayer src={siteOptions.main_soundtrack} />
            }
          </div>
        )}
      />
    </BrowserRouter>
  );
}

export default App;
