import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Item = (props) => {

  const { poem, pathname, map, mapCharWidth } = props;
  const [poemPosition, setPoemPosition] = useState({
    x: poem.x + '%',
    y: poem.y + '%'
  })

  useEffect(() => {
    if (map && poem.x && poem.y) {
      const x = Math.round((poem.x / 100) * mapCharWidth);
      const y = Math.round((poem.y / 100) * 120);

      setPoemPosition({
        x: Math.round(x * 0.982) + 'em',
        y: Math.round(y * 1.01) + 'em'
      })
    }

  }, [poem.x, poem.y, map, mapCharWidth]);

  return (

    <motion.button
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={() => { props.history.push(`/poem/${poem.title_slug}`); }}
      className={`map__link${pathname.indexOf(poem.title_slug) > -1 ? ' open' : ''}`}
      style={{
        left: poemPosition.x,
        top: poemPosition.y,
        padding: '0',
        position: 'absolute',
        marginLeft: '-0.5em',
        backgroundColor: 'black',
        color: 'yellow',
        height: '2.8em',
        lineHeight: 1.4 + 'em',
        textTransform: 'lowercase',
        userSelect: 'none',
        whiteSpace: 'pre',
        zIndex: 999,
        paddingRight: '0.7em',
        paddingLeft: '0.7em',
      }}
    >{poem.title}</motion.button>
  )
}

const Menu = props => {

  const { siteOptions, poemsData, pathname, app } = props;
  const { map, map_char_w } = siteOptions;

  return (
    <div
      className="canvas__holder"
    >
      <div
        className="map__wrapper"
        style={{
          maxWidth: 'fit-content',
          width: 'auto',
          height: 'auto'
        }}
      >
        <div
          className="map__outer"
          style={{
            position: 'relative',
            display: 'inline-block',
            minWidth: '100%',
            minHeight: '100%',
            transform: 'translate(-50%, -25%)'
          }}
        >
          <motion.div
            className="map__inner"
            drag
            dragConstraints={app}
            style={{
              width: 'fit-content'
            }}
          >
            <p
              style={{
                margin: 0,
                whiteSpace: 'pre',
                pointerEvents: 'none',
                userSelect: 'none',
                lineHeight: 1.4 + 'em',
                display: 'inline-block',
                zIndex: 1,
                position: 'relative'
              }}
            >{map}</p>
            {
              pathname !== '/' &&
              pathname !== '/reflection' &&
              poemsData.entries &&
              poemsData.entries.map(
                (item, i) => (
                  <Item poem={item} key={i} {...props} map={map} mapCharWidth={map_char_w} />
                )
              )
            }
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Menu;