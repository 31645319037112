import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Editors = (props) => {

  const { poemsData, fetchPoemsData } = props;

  useEffect(() => {
    fetchPoemsData();
  }, [fetchPoemsData]);

  return (
    <div className="view--editors">
      <ul>
        {
          poemsData.entries &&
          poemsData.entries.map(
            (poem, i) => (
              poem.title_slug &&
              <li key={i}>
                <Link to={`/editor/${poem.title_slug}`}>
                  {poem.title_slug}
                </Link>
              </li>
            )
          )
        }
        <li>
          <Link to="/new-poem/">Add new Poem</Link>
        </li>
      </ul>
    </div>
  );
}

export default Editors;