const cockpitUrl = 'https://char.rif.ke/internetwalks';
const assetsPath = 'https://char.rif.ke/internetwalks/';

const getImage = (path, w, h) => {
  const url = `${cockpitUrl}/api/cockpit/image?token=${process.env.REACT_APP_API_KEY}&src=${path}&w=${w}&h=${h}&o=true`;
  return url;
}

const fetchSingletonData = (singletonName) => {
  console.log(singletonName);
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/singletons/get/${singletonName}?token=${apiKey}`)
  .then(response => response.json())
  .then(response => {
    return response
  })
  .catch(error => {
    console.log(error);
  })
}

const fetchCollectionData = (collectionName) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/collections/get/${collectionName}?token=${apiKey}`)
  .then(response => response.json())
  .then(response => {
    return response
  })
  .catch(error => {
    console.log(error);
  })
}
 
export { fetchSingletonData, fetchCollectionData, cockpitUrl, assetsPath, getImage };