import { useRef, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const About = (props) => {

  const { windowDimensions, siteText, app } = props;
  const modal = useRef();
  const { windowWidth, windowHeight } = windowDimensions;
  const [isPositioned, setIsPositioned] = useState(false);
  const [pos, setPos] = useState({
    left: 0,
    top: 0
  });
  const { about } = siteText;

  useEffect(() => {
    if (windowWidth && windowHeight && modal.current && about) {
      setPos({
        left: windowWidth / 2 - modal.current.offsetWidth / 2 + 'px',
        top: windowHeight / 2 - modal.current.offsetHeight / 2 + 'px'
      });
      setIsPositioned(true);
    }
  }, [windowWidth, windowHeight, about]);

  return (
    <motion.div
      ref={modal}
      drag={windowWidth < 768 ? false : true}
      dragConstraints={app}
      dragElastic={0.2}
      className={`view view--about modal full ${isPositioned === true ? 'visible' : 'hidden'}`}
      style={{
        left: pos.left,
        top: pos.top,
        maxHeight: windowHeight - 24 + 'px'
      }}
    >
      <div className="modal__inner">
        <div className="modal__header">
          <div className="modal__header__inner">
              <Link
                className="folder__header__close modal__header__button modal__header__button--close"
                to={'/menu'}
            >&times;</Link>
            {
              windowWidth < 768 ?
                <h1 className="modal__header__title">About</h1>
                :
                <h1 className="modal__header__title">How do you take a walk with someone on the internet?</h1>
            }
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__content__inner">
            {
              about &&
              <ReactMarkdown>{about.replace(/\n/gi, '\n&nbsp;  ')}</ReactMarkdown>
            }
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default About;