import { Fragment } from 'react';
import { useEffect, useRef } from 'react';
import { assetsPath } from '../utils/cockpit.js';

const ClickAudioPlayer = (props) => {
  const { siteOptions } = props;
  const clicksPlayer = useRef();

  useEffect(() => {
    const playClicksSound = () => {
      if (clicksPlayer.current) {
        clicksPlayer.current.play();
      }
    }

    window.addEventListener('mousedown', playClicksSound);

    return () => {
      window.removeEventListener('mousedown', playClicksSound);
    };
  }, []);

  return (
    <Fragment>
      {
        siteOptions.click_sound && siteOptions.click_sound !== '' &&
        <audio
          ref={clicksPlayer}
          volume={0.5}
          src={assetsPath + siteOptions.click_sound}
        />
      }
    </Fragment>
  );
}

export default ClickAudioPlayer;