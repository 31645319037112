import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { cockpitUrl } from '../utils/cockpit.js';
import map from '../../assets/map1.svg';

const getParentFolder = (newPoem, path, data) => {
  console.clear();

  const currentPath = [];
  for (let i = 0; i < path.length; i++) {
    currentPath.push(path[i]);
  }
  currentPath.push(data.name);

  const depth = path.length;

  let currentFolder = newPoem;

  //find parent folder of current item
  for (let i = 0; i < depth; i++) {
    for (let j = 0; j < currentFolder.contents.length; j++) {
      if (currentFolder.contents[j].name === path[i]) {
        currentFolder = currentFolder.contents[j];
      }
    }
  }

  return currentFolder;
}

const File = ({ data, poem, setPoem, path }) => {

  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [renameText, setRenameText] = useState(data.name);
  const [editText, setEditText] = useState(data.content);
  const [charWidthValue, setCharWidthValue] = useState(data.char_w ? data.char_w : 200);

  const handleRename = (e) => {
    e.preventDefault();
    const newPoem = { ...poem };
    const currentFolder = getParentFolder(newPoem, path, data);
    // now we've got the parent folder, iterate through it to find our current item and change the name, then
    if (currentFolder.contents) {
      for (let item of currentFolder.contents) {
        if (item.name === data.name) {
          item.name = renameText;
        }
      }
    }

    setPoem(newPoem);
    setIsRenaming(false);
  }

  const handleDelete = (e) => {
    e.preventDefault();
    const newPoem = { ...poem };
    const currentFolder = getParentFolder(newPoem, path, data);
    // now we've got the parent folder, iterate through it to find our current item and change the name, then
    if (currentFolder.contents) {
      let i = 0;
      for (let item of currentFolder.contents) {
        if (item.name === data.name) {
          currentFolder.contents.splice(i, 1);
        }
        i++;
      }
    }

    setPoem(newPoem);
    setIsDeleting(false);
  }

  const handleEdit = (e) => {
    e.preventDefault();
    const newPoem = { ...poem };
    const currentFolder = getParentFolder(newPoem, path, data);
    // now we've got the parent folder, iterate through it to find our current item and change the name, then
    if (currentFolder.contents) {
      for (let item of currentFolder.contents) {
        if (item.name === data.name) {
          item.content = editText;
          item.char_w = charWidthValue;
        }
      }
    }

    setPoem(newPoem);
    setIsEditing(false);
  }

  return (
    <li className={`editor__file`}>
      <p className="editor__file__p">{data.name}</p>
      {
        isRenaming === false && isDeleting === false && isEditing === false &&
        <div className="editor__item__buttons">
          <button onClick={() => { setIsRenaming(true) }}>Rename</button>
          <button onClick={() => { setIsEditing(true) }}>Edit</button>
          <button onClick={() => { setIsDeleting(true) }}>Delete</button>
        </div>
      }
      {
        isRenaming === true &&
        <div className="editor__item__buttons--renaming">
          <form onSubmit={handleRename}>
            <input className="rename" value={renameText} autoFocus type="text" onChange={(e) => {setRenameText(e.target.value); }} />
            <input type="submit" value="Confirm" />
            <button onClick={() => { setIsRenaming(false) }}>Cancel</button>
          </form>
        </div>
      }
      {
        isDeleting === true &&
        <div className="editor__item__buttons--deleting">
          <form onSubmit={handleDelete}>
            <input type="submit" value="Delete" />
            <button onClick={() => { setIsDeleting(false) }}>Cancel</button>
          </form>
        </div>
      }
      {
        isEditing === true &&
        <div className="editor__item__buttons--editing">
          <form onSubmit={handleEdit}>
            <textarea
              value={editText}
              onChange={(e) => {
                setEditText(e.target.value);
              }}
              rows={12}
              style={{
                width: charWidthValue * 0.66 + 'em'
              }}
            />
            <input type="number" value={charWidthValue} onChange={(e) => { setCharWidthValue(e.target.value) }} />
            <input type="submit" value="Confirm" />
            <button onClick={() => { setIsEditing(false); setEditText(data.content); }}>Cancel</button>
          </form>
        </div>
      }
    </li>
  )
}

const Folder = ({ data, path, poem, setPoem, poemDataRef }) => {

  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddingChildFile, setIsAddingChildFile] = useState(false);
  const [isAddingChildFolder, setIsAddingChildFolder] = useState(false);
  const [renameText, setRenameText] = useState(data.name);
  const [newFolderNameText, setNewFolderNameText] = useState('');
  const [newFileNameText, setNewFileNameText] = useState('');
  const [newFileContent, setNewFileContent] = useState('');
  const [currentPath, setCurrentPath] = useState([]);

  useEffect(() => {
    const newPath = [];
    for (let item of path) {
      newPath.push(item);
    }
    newPath.push(data.name);
    setCurrentPath(newPath);
  }, [path, data.name]);

  const handleRename = (e) => {
    e.preventDefault();
    const newPoem = { ...poem };
    const currentFolder = getParentFolder(newPoem, path, data);
    // now we've got the parent folder, iterate through it to find our current item and change the name, then
    if (currentFolder.contents) {
      for (let item of currentFolder.contents) {
        if (item.name === data.name) {
          item.name = renameText;
        }
      }
    }

    setPoem(newPoem);
    setIsRenaming(false);
  }

  const handleDelete = (e) => {
    e.preventDefault();
    const newPoem = { ...poem };
    const currentFolder = getParentFolder(newPoem, path, data);
    // now we've got the parent folder, iterate through it to find our current item and change the name, then
    if (currentFolder.contents) {
      let i = 0;
      for (let item of currentFolder.contents) {
        if (item.name === data.name) {
          currentFolder.contents.splice(i, 1);
        }
        i++;
      }
    }

    setPoem(newPoem);
    setIsDeleting(false);
  }

  const handleAddChildFolder = (e) => {
    e.preventDefault();
    const newPoem = { ...poem };
    const currentFolder = getParentFolder(newPoem, path, data);
    // now we've got the parent folder, iterate through it to find our current item and change the name, then
    if (currentFolder.contents) {
      for (let item of currentFolder.contents) {
        if (item.name === data.name) {
          const newFolder = {
            name: newFolderNameText,
            type: "folder",
            contents: []
          }
          item.contents.push(newFolder);
        }
      }
    }

    setNewFolderNameText('');
    setPoem(newPoem);
    setIsAddingChildFolder(false);
  }

  const handleAddFile = (e) => {
    e.preventDefault();
    const newPoem = { ...poem };
    const currentFolder = getParentFolder(newPoem, path, data);
    // now we've got the parent folder, iterate through it to find our current item and change the name, then
    if (currentFolder.contents) {
      for (let item of currentFolder.contents) {
        if (item.name === data.name) {
          const newFile = {
            name: newFileNameText,
            type: "file",
            content: newFileContent
          }
          item.contents.push(newFile);
        }
      }
    }

    setNewFileNameText('');
    setNewFileContent('');
    setPoem(newPoem);
    setIsAddingChildFile(false);
  }

  return (
    <li className={`editor__folder`}>
      <p className="editor__folder__p">{data.name}</p>
      {
        isRenaming === false && isDeleting === false && isAddingChildFile === false && isAddingChildFolder === false &&
        <div className="editor__item__buttons">
          <button onClick={() => { setIsRenaming(true) }}>Rename</button>
          <button onClick={() => { setIsDeleting(true) }}>Delete</button>
          <button onClick={() => { setIsAddingChildFolder(true) }}>New folder</button>
          <button onClick={() => { setIsAddingChildFile(true) }}>New file</button>
        </div>
      }
      {
        isRenaming === true &&
        <div className="editor__item__buttons--renaming">
          <form onSubmit={handleRename}>
            <input className="rename" value={renameText} autoFocus type="text" onChange={(e) => {setRenameText(e.target.value); }} />
            <input type="submit" value="Confirm" />
            <button onClick={() => { setIsRenaming(false) }}>Cancel</button>
          </form>
        </div>
      }
      {
        isDeleting === true &&
        <div className="editor__item__buttons--deleting">
          <form onSubmit={handleDelete}>
            <p>Are you sure you want to delete this folder? All of the folders and files it contains will also be removed.</p>
            <input type="submit" value="Delete" />
            <button onClick={() => { setIsDeleting(false) }}>Cancel</button>
          </form>
        </div>
      }
      {
        isAddingChildFolder === true &&
        <div className="editor__item__buttons--new-folder">
          <form onSubmit={handleAddChildFolder}>
            <label htmlFor="new folder name">New folder name</label>
            <input className="add-folder" value={newFolderNameText} autoFocus type="text" onChange={(e) => { setNewFolderNameText(e.target.value); }} name="new folder name" />
            <input type="submit" value="Add" />
            <button onClick={() => { setIsAddingChildFolder(false); setNewFolderNameText(''); }}>Cancel</button>
          </form>
        </div>
      }
      {
        isAddingChildFile === true &&
        <div className="editor__item__buttons--new-file">
          <form onSubmit={handleAddFile}>
            <label htmlFor="new file name">New file name</label>
            <input className="add-file" value={newFileNameText} autoFocus type="text" onChange={(e) => { setNewFileNameText(e.target.value); }} name="new file name" />
            <label htmlFor="new file name">New file content</label>
            <textarea className="add-file" value={newFileContent} onChange={(e) => { setNewFileContent(e.target.value); }} name="new file content" />
            <input type="submit" value="Add" />
            <button onClick={() => { setIsAddingChildFile(false); setNewFileNameText(''); }}>Cancel</button>
          </form>
        </div>
      }
      <FolderList data={data} poem={poem} setPoem={setPoem} path={currentPath} poemDataRef={poemDataRef} />

    </li>
  )
}

const FolderList = ({ data, poem, setPoem, path, poemDataRef }) => {

  return (
    <ul className="editor__folder__list">
      {
        data.contents &&
        data.contents.map(
        (item, i) => (
          item.type === "folder" ?
            <Folder
              data={item}
              key={i}
              index={i}
              poem={poem}
              setPoem={setPoem}
              path={path}
              poemDataRef={poemDataRef}
            />
            :
            <File
              data={item}
              key={i}
              index={i}
              poem={poem}
              setPoem={setPoem}
              path={path}
              poemDataRef={poemDataRef}
            />
          )
        )
      }
    </ul>
  )
}

const EditorMap = ({ poem, setPoemMapCoordinates }) => {

  const [mapCoordinates, setMapCoordinates] = useState({
    x: poem.x ? poem.x : 0,
    y: poem.y ? poem.y : 0
  });
  const [isVisible, setIsVisible] = useState(false);

  const img = useRef();
  const content = useRef();
  const modal = useRef();

  const handleSetMapCoordinates = (e) => {
    const width = img.current.offsetWidth;
    const height = img.current.offsetHeight;
    const left = modal.current.offsetLeft + content.current.offsetLeft + img.current.offsetLeft;
    const top = modal.current.offsetTop + content.current.offsetTop + img.current.offsetTop;

    const mouseX = e.clientX;
    const mouseY = e.clientY;

    const x = Math.round((mouseX - left) / width * 100);
    const y = Math.round((mouseY - top) / height * 100);

    setMapCoordinates({
      x: x,
      y: y
    });

    const newPoem = poem;
    newPoem.x = x;
    newPoem.y = y;
    setPoemMapCoordinates({
      x: x,
      y: y
    });
  }

  const handleToggleVisible = () => {
    setIsVisible(!isVisible);
  }

  return (
    <div
      className={`modal visible modal--map${isVisible === false ? ' minimised' : ''}`}
      ref={modal}
      style={{
        width: '40vmax',
        height: '22.5vmax'
      }}
    >
      <div className="modal__inner">
        <div className="modal__header">
          <div className="modal__header__inner">
            <button
              className="modal__header__button modal__header__button--minimise"
              onClick={handleToggleVisible}
            >{isVisible === true ? '-' : '+'}</button>
            Place on map
          </div>
        </div>
        {
          isVisible === true &&
          <div
            className="modal__content"
            ref={content}
            style={{
              width: '100%',
            }}
            onClick={handleSetMapCoordinates}
          >
            <div
              className="modal__content__inner modal__content__inner--map"
            >
              <img
                ref={img}
                src={map}
                className="editor__map"
                alt="world map"
                style={{
                  width: '100%',
                  display: 'block',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  zIndex: 1,
                  pointerEvents: 'none'
                }}
              />
              <div
                className="coordinates-indicator"
                style={{
                  display: 'block',
                  backgroundColor: 'hotpink',
                  width: '12px',
                  height: '12px',
                  position: 'absolute',
                  borderRadius: '50%',
                  zIndex: 4,
                  transform: 'translate(-50%,-50%)',
                  opacity: 0.8,
                  left: mapCoordinates.x + '%',
                  top: mapCoordinates.y + '%'
                }}
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const Editor = (props) => {

  const { pathname, poemsData, fetchPoemsData } = props;
  const [poemPost, setPoemPost] = useState({});
  const [poem, setPoem] = useState({});
  const [currentPath, setCurrentPath] = useState([poem.name]);
  const [isSaving, setIsSaving] = useState(false);
  const [isErrorSaving, setIsErrorSaving] = useState(false);
  const [poemMapCoordinates, setPoemMapCoordinates] = useState({});

  useEffect(() => {
    fetchPoemsData();
  }, [fetchPoemsData]);

  const updatePoemInCMS = (exit) => {
    setIsSaving(true);
    setIsErrorSaving(false);

    const newPoemPost = { ...poemPost };
    newPoemPost.folder = poem;
    newPoemPost.x = poemMapCoordinates.x;
    newPoemPost.y = poemMapCoordinates.y;

    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/collections/save/poems?token=${apiKey}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: newPoemPost
      })
    })
    .then(res => res.json())
      .then((res) => {
        setIsSaving(false);
    })
    .catch(error => {
      console.log(error);
      setIsErrorSaving(true);
    });

    if (exit === true) {
      props.history.push('/editors');
    }
  }

  const deletePoem = () => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/collections/remove/poems?token=${apiKey}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filter: {
          _id: poemPost._id
        }
      })
    })
      .then(() => {
        props.history.push('/editors');
      })
    .catch(error => console.log(error))
  }

  useEffect(() => {
    if (poemsData.entries) {
      const slug = pathname.replace('/editor/', '');
      for (let item of poemsData.entries) {
        if (item.title_slug === slug) {
          if (item.folder) {
            setPoem({ ...item.folder });
            setCurrentPath([item.folder.name]);
            setPoemPost(item);
            setPoemMapCoordinates({
              x: poemPost.x,
              y: poemPost.y
            });
          }
        }
      }
    }
  }, [pathname, poemsData, poemPost.x, poemPost.y]);

  return (
    <div className="view--editor">
      <div className="editor__folders__wrapper">
        <div className="editor__folder__inner">
          {poem.name}
          <div className="editor__folder__content">
            <div className="editor__folder__content__inner">
              {
                poem.contents &&
                poem.contents.map(
                  (item, i) => (
                    i === 0 &&
                    <Folder
                      data={item}
                      index={i}
                      key={i}
                      depth={i + 1}
                      poem={poem}
                      setPoem={setPoem}
                      path={currentPath}
                      poemDataRef={poem.contents}
                    />
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
      <EditorMap poem={poem} setPoemMapCoordinates={setPoemMapCoordinates} />
      <div className="editor__buttons">
        <button className="editor__button editor__button--save" onClick={updatePoemInCMS}>{
          isSaving === false && isErrorSaving === false ?
            'Save changes'
            :
            isSaving === true ?
              'Saving...'
              :
              'Error, please try again'
        }</button>
        <button className="editor__button editor__button--save--exit" onClick={() => { updatePoemInCMS(true); }}>Save and exit</button>
        <button className="editor__button editor__button--save--exit" onClick={() => { deletePoem(); }}>Delete Poem</button>
        <Link className="editor__button editor__button--discard--exit" to="/editors">Discard changes and exit</Link>
      </div>
    </div>
  );
}

export default Editor;