import { Fragment, useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import AudioPlayers from '../components/AudioPlayer';

const FileModal = (props) => {

  const { name, content, setOpenFile, char_w, windowDimensions, app } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  const [size, setSize] = useState('small');
  const [isPositioned, setIsPositioned] = useState(false);
  const [originalPosition] = useState({
    x: windowWidth > 767 ? Math.floor(Math.random() * 120) + 120 : 12,
    y: windowWidth > 767 ? Math.floor(Math.random() * 120) + 120 : 12
  });

  useEffect(() => {
    setTimeout(() => {
      setSize('full');
      setIsPositioned(true);
    }, 12);
  }, []);

  return (
    <motion.div
      drag={windowWidth > 767 ? true : false}
      dragConstraints={app}
      style={{
        top: originalPosition.y + 'px',
        left: originalPosition.x + 'px',
        width: char_w * 0.66 + 'em',
        maxHeight: windowHeight - 24 + 'px',
        maxWidth: size === 'small' ? 0 : windowWidth < 768 ? windowWidth - 24 + 'px' : windowWidth * 0.8 + 'px'
      }}
      dragElastic={0.2}
      className={`modal file--open${size === 'full' ? ' full' : ''} ${isPositioned === true ? 'visible' : 'hidden'}`}>
      <div className="modal__inner">
        <div className="file--open__header modal__header">
          <div className="modal__header__inner">
            <button
              className="file--open__header__close modal__header__button modal__header__button--close"
              aria-label="Close file"
              onClick={() => { setOpenFile({ noFileIsOpen: true }) }}
            >&times;</button>
            <h2 className="modal__header__title">{name}</h2>
          </div>
        </div>
        <div className="file--open__content modal__content">
          <p className="file--open__content__text"
            style={{
              width: char_w * 0.66 + 'em'
            }}
          >
            {content}
          </p>
        </div>
      </div>
      </motion.div>
  )
}

const File = ({ data, openFile, setOpenFile }) => {

  return (
    <Fragment>
      <li className={`file${openFile.name === data.name ? ' open' : ''}`}>
        <button
          className="file__button"
          onClick={() => {
            setOpenFile(data);
          }}
        >{data.name}</button>
      </li>
    </Fragment>
  )
}

const Folder = ({ data, index, openIndex, setOpenIndex, setOpenFile, openFolders, setOpenFolders, depth, folderInner }) => {

  const handleClick = () => {
    const newOpenFoldersArray = [];
    for (let i = 0; i < Math.min(depth, openFolders.length); i++) {
      const openFolder = openFolders[i];
      newOpenFoldersArray.push({ ...openFolder });
    }
    if (index !== openIndex) {
      setOpenIndex(index);
      newOpenFoldersArray.push({ ...data });
    } else {
      setOpenIndex(-1);
    }
    setOpenFolders(newOpenFoldersArray);
    // close open file if one is open
    setOpenFile({ noFileIsOpen: true });

    const scrollLeft = () => {
      folderInner.current.scrollLeft = folderInner.current.scrollWidth;
    }

    setTimeout(() => {
      scrollLeft();
    }, 1);
  }

  return (
    <Fragment>
      <li className={`folder${openIndex === index ? ' open' : ''}`}>
        <button
          className="folder__button"
          onClick={handleClick}
        >{data.name}</button>
      </li>
    </Fragment>
  )
}

const FolderList = ({ data, setOpenFile, openFolders, openFile, setOpenFolders, depth, folderInner }) => {

  const [contentsOpenIndex, setContentsOpenIndex] = useState(-1);

  return (
    <motion.ul
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="folder__list"
    >
      {
        data.contents &&
        data.contents.map(
        (item, i) => (
          item.type === "folder" ?
            <Folder
              data={item}
              key={i}
              index={i}
              openIndex={contentsOpenIndex}
              setOpenIndex={setContentsOpenIndex}
              setOpenFile={setOpenFile}
              openFolders={openFolders}
              setOpenFolders={setOpenFolders}
              depth={depth}
              folderInner={folderInner}
            />
            :
            <File
              data={item}
              key={i}
              index={i}
              openIndex={contentsOpenIndex}
              setOpenIndex={setContentsOpenIndex}
              openFile={openFile}
              setOpenFile={setOpenFile}
            />
          )
        )
      }
    </motion.ul>
  )
}

const Poem = (props) => {

  const { pathname, poemsData, windowDimensions, app } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [openFile, setOpenFile] = useState({
    noFileIsOpen: true
  });
  const modal = useRef();
  const [openFolders, setOpenFolders] = useState([]);
  const [poem, setPoem] = useState({});
  const folderInner = useRef();
  const [isPositioned, setIsPositioned] = useState(false);
  const [pos, setPos] = useState({
    left: 0,
    top: 0
  });

  useEffect(() => {
    if (poemsData.entries) {
      const slug = pathname.replace('/poem/', '');
      for (let item of poemsData.entries) {
        if (item.title_slug === slug) {
          if (item.folder) {
            setPoem({ ...item });
            setOpenFolders([{ ...item.folder }]);
          }
        }
      }
    }
  }, [pathname, poemsData]);

  useEffect(() => {
    if (windowWidth && windowHeight && modal.current) {
      setPos({
        left: windowWidth / 2 - modal.current.offsetWidth / 2 + 'px',
        top: windowHeight / 2 - modal.current.offsetHeight / 2 + 'px'
      });
      setIsPositioned(true);
    }
  }, [windowWidth, windowHeight]);

  return (
    <Fragment>
      <motion.div
        ref={modal}
        className={`folders__wrapper modal modal--folder ${isPositioned === true ? 'visible' : 'hidden'}`}
        drag={windowWidth > 767 ? true : false}
        dragConstraints={app}
        style={{
          left: pos.left,
          top: pos.top,
          maxHeight: windowHeight - 24 + 'px'
        }}
        dragElastic={0.2}
      >
        <div className="modal__inner modal--folder__inner">
          <div className="modal__header modal--folder__header">
            <div className="modal__header__inner">
              <Link
                className="folder__header__close modal__header__button modal__header__button--close"
                to={'/menu'}
              >&times;</Link>
              <h2 className="modal__header__title">{poem.title_slug}</h2>
            </div>
          </div>
          <div
            className="modal__content modal--folder__content"
            ref={folderInner}
          >
            <div className="modal--folder__content__inner">
              <AnimatePresence>
                {
                  openFolders &&
                  openFolders.map(
                    (item, i) => (
                      <FolderList
                        data={item}
                        index={i}
                        key={i}
                        setOpenFile={setOpenFile}
                        openFile={openFile}
                        openFolders={openFolders}
                        setOpenFolders={setOpenFolders}
                        depth={i + 1}
                        folderInner={folderInner}
                      />
                    )
                  )
                }
              </AnimatePresence>
            </div>
          </div>
        </div>
      </motion.div>
      {
        openFile.noFileIsOpen !== true &&
        <FileModal {...openFile} setOpenFile={setOpenFile} windowDimensions={windowDimensions} app={app} />
      }
      {
        poem.soundtrack && poem.soundtrack !== '' &&
        <AudioPlayers src={poem.soundtrack} />
      }
    </Fragment>
  );
}

export default Poem;