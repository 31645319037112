import { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import { assetsPath } from '../utils/cockpit.js';

const AudioPlayers = (props) => {
  const { src } = props;
  const audioPlayer = useRef();
  const [isInteracted, setIsInteracted] = useState(false);

  useEffect(() => {
    const playBackgroundSound = () => {
      if (audioPlayer.current) {
        audioPlayer.current.play()
        .then(() => { setIsInteracted(true) })
        .catch(error => console.error(error));
        window.removeEventListener('mousedown', playBackgroundSound);
        window.removeEventListener('mousemove', playBackgroundSound);
        window.removeEventListener('click', playBackgroundSound);
        window.removeEventListener('touchstart', playBackgroundSound);
      }
    }

    if (isInteracted === false) {
      window.addEventListener('mousedown', playBackgroundSound);
      window.addEventListener('mousemove', playBackgroundSound);
      window.addEventListener('click', playBackgroundSound);
      window.addEventListener('touchstart', playBackgroundSound);
    }

    return () => {
      window.removeEventListener('mousedown', playBackgroundSound);
      window.removeEventListener('mousemove', playBackgroundSound);
      window.removeEventListener('click', playBackgroundSound);
      window.removeEventListener('touchstart', playBackgroundSound);
    }

  }, [isInteracted]);

  return (
    <Fragment>
      {
        src && src !== '' &&
        <audio
          ref={audioPlayer}
          volume={1}
          src={assetsPath + src}
        />
      }
    </Fragment>
  );
}

export default AudioPlayers;