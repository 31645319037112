import { useState } from 'react';
import { Link } from 'react-router-dom';
import { cockpitUrl } from '../utils/cockpit.js';
import slugify from 'slugify';

const Editor = (props) => {
  const { poemsData } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [isErrorSaving, setIsErrorSaving] = useState(false);
  const [isTitleTaken, setIsTitleTaken] = useState(false);
  const [poemTitle, setPoemName] = useState('');

  const handlePoemNameChange = (e) => {
    setPoemName(e.target.value);
    let titleTaken = 0;
    if (poemsData.entries) {
      for (let poem of poemsData.entries) {
        if (e.target.value.length > 0 && poem.title_slug) {
          if (slugify(e.target.value) === poem.title_slug) {
            titleTaken = 1;
          }
        }
      }
    }

    if (titleTaken === 1) {
      setIsTitleTaken(true);
    } else {
      setIsTitleTaken(false);
    }
  }

  const createPoemInCMS = (e) => {
    e.preventDefault();

    if (poemTitle !== '' && isTitleTaken === false) {
      setIsSaving(true);
      setIsErrorSaving(false);

      const newPoem = {
        title: poemTitle,
        title_slug: slugify(poemTitle),
        folder: {
          contents: [
            {
              name: slugify(poemTitle),
              type: "folder",
              contents: []
            }
          ]
        },
      };

      const apiKey = process.env.REACT_APP_API_KEY;
      fetch(`${cockpitUrl}/api/collections/save/poems?token=${apiKey}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: newPoem
        })
      })
        .then(res => res.json())
        .then((res) => {
          setIsSaving(false);
          props.history.push(`/editor/${slugify(poemTitle)}`);
        })
        .catch(error => {
          console.log(error);
          setIsErrorSaving(true);
        });
    }
  }

  return (
    <div>
      <form className="new-poem__form" onSubmit={createPoemInCMS}>
        <h1>New Poem</h1>
        <input type="text" value={poemTitle} onChange={handlePoemNameChange} />
        {
          isTitleTaken === true &&
          <p>That name is already taken. Please choose another name, or head to the <Link to={`/editor/${slugify(poemTitle)}`}>editor</Link> to edit {slugify(poemTitle)}.</p>
        }
        {
          isTitleTaken === false &&
          <input className="editor__input editor__button--save--exit" type="submit" value={
            isSaving === false && isErrorSaving === false ?
              'Save changes'
              :
              isSaving === true ?
                'Saving...'
                :
                'Error, please try again'
          } />
        }
        <Link className="new-poem__button new-poem__button--discard--exit blocklink" to="/editors">Discard changes and exit</Link>
      </form>
    </div>
  );
}

export default Editor;